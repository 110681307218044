import React, { Suspense } from 'react'
import { useTranslation } from 'react-i18next'
import { Route, Routes } from 'react-router-dom'
import Loader from './reusableComponents/Loader'

const Cart = React.lazy(() => import('../pages/Cart'))
const Category = React.lazy(() => import('../pages/Category'))
const Home = React.lazy(() => import('../pages/Home'))
const ProductDetail = React.lazy(() => import('../pages/ProductDetail'))
const User = React.lazy(() => import('../pages/user/User'))
const Login = React.lazy(() => import('../pages/Login'))
const NotFound = React.lazy(() => import('../pages/NotFound'))
const ProfileDetail = React.lazy(() => import('../pages/ProfileDetail'))
const CreateOrder = React.lazy(() => import('../pages/createOrder/CreateOrder'))
const OrderConfirmation = React.lazy(() => import('../pages/OrderConfirmation'))
const OrderDetail = React.lazy(() => import('../pages/OrderDetail'))
const PasswordReset = React.lazy(() => import('../pages/PasswordReset'))
const PasswordRestore = React.lazy(() => import('../pages/PasswordRestore'))
const ReviewDetail = React.lazy(() => import('../pages/ReviewDetail'))
const FinanceHistory = React.lazy(() => import('../pages/user/FinanceHistory'))
const CMSDetail = React.lazy(() => import('../pages/CMSDetail'))
const Contact = React.lazy(() => import('../pages/Contact'))
const CookiePolicy = React.lazy(() => import('../pages/CookiePolicy'))

const OrdersHistory = React.lazy(() => import('../pages/user/OrdersHistory'))
const UserSettings = React.lazy(() => import('../pages/user/UserSettings'))
const RegisterSeller = React.lazy(() => import('../pages/RegisterSeller'))
const RegisterAccount = React.lazy(() => import('../pages/RegisterAccount'))
const ProductList = React.lazy(() => import('../pages/user/ProductList'))
const ProductEdit = React.lazy(() => import('../pages/user/ProductEdit'))
const Bidding = React.lazy(() => import('../pages/user/Bidding'))
const Reviews = React.lazy(() => import('../pages/user/Reviews'))
const Absence = React.lazy(() => import('../pages/user/Absence'))
const Activation = React.lazy(() => import('../pages/Activation'))
const WatchedProducts = React.lazy(
  () => import('../pages/user/WatchedProducts')
)
const WatchedSellers = React.lazy(() => import('../pages/user/WatchedSellers'))
// const Notifications = React.lazy(() => import('../pages/user/Notifications'))

const SuspenseLoader = () => {
  return (
    <div className="container min-h-[80vh]">
      <Loader />
    </div>
  )
}

const RoutesManager = () => {
  const { t } = useTranslation('global', { keyPrefix: 'urls' })

  return (
    <Suspense fallback={<SuspenseLoader />}>
      <Routes>
        <Route index element={<Home />} />
        <Route
          path={`/${t('category')}/:categorySlug/:sellerSlug?`}
          element={<Category />}
        />
        <Route path={`/${t('contact')}`} element={<Contact />} />
        <Route path={`/${t('search')}`} element={<Category search />} />
        <Route path={`/${t('product')}/:id`} element={<ProductDetail />} />
        <Route
          path={`/${t('profile_detail')}/:id`}
          element={<ProfileDetail />}
        />
        <Route path={`/${t('cms')}/:id`} element={<CMSDetail />} />

        <Route path={`/${t('cart')}`} element={<Cart />} />
        <Route path={`/${t('order')}`} element={<CreateOrder />} />
        <Route
          path={`/${t('order_confirmation')}`}
          element={<OrderConfirmation />}
        />
        <Route path={`/${t('order_detail')}/:id`} element={<OrderDetail />} />
        <Route path={`/${t('review_detail')}/:id`} element={<ReviewDetail />} />

        <Route
          path={`/${t('register_account')}`}
          element={<RegisterAccount />}
        />
        <Route
          path={`/${t('activate_account')}/:code`}
          element={<Activation />}
        />
        <Route path={`/${t('register_seller')}`} element={<RegisterSeller />} />
        <Route path={`/${t('login')}`} element={<Login />} />
        <Route
          path={`/${t('forgotten_password')}`}
          element={<PasswordReset />}
        />
        <Route
          path={`/${t('password_recovery')}`}
          element={<PasswordRestore />}
        />

        <Route path={`/${t('account')}`} element={<User />}>
          <Route index element={<OrdersHistory />} />
          <Route path={t('user.orders_history')} element={<OrdersHistory />} />
          <Route path={t('user.user_settings')} element={<UserSettings />} />
          <Route path={t('user.my_products')} element={<ProductList />} />
          <Route path={t('user.bidding')} element={<Bidding type="list" />} />
          <Route path={t('user.review')} element={<Reviews />} />
          <Route path={t('user.absence')} element={<Absence />} />
          {/* <Route path={t('user.notifications')} element={<Notifications />} /> */}
          <Route
            path={`${t('user.my_products')}/:id`}
            element={<ProductEdit />}
          />
          <Route
            path={`${t('user.my_products')}/:id/${t('user.duplicate')}`}
            element={<ProductEdit duplicate />}
          />
          <Route
            path={`${t('user.my_products')}/:id/${t('user.bidding')}`}
            element={<Bidding type="detail" />}
          />
          <Route
            path={t('user.finance_history')}
            element={<FinanceHistory />}
          />
          <Route
            path={t('user.watched_products')}
            element={<WatchedProducts />}
          />
          <Route
            path={t('user.watched_sellers')}
            element={<WatchedSellers />}
          />
          <Route path="*" element={<NotFound />} />
        </Route>

        {/* Pages */}
        <Route
          path={t('pages.terms-and-conditions-of-use')}
          element={<CMSDetail _id={3} />}
        />
        <Route path={t('pages.about-us')} element={<CMSDetail _id={4} />} />
        <Route
          path={t('pages.complaints-procedure')}
          element={<CMSDetail _id={14} />}
        />
        <Route path={t('pages.contact')} element={<CMSDetail _id={10} />} />
        <Route path={t('pages.cookie-policy')} element={<CookiePolicy />} />
        <Route
          path={t('pages.frequently-asked-questions')}
          element={<CMSDetail _id={13} />}
        />
        <Route
          path={t('pages.general-terms-and-conditions')}
          element={<CMSDetail _id={6} />}
        />
        <Route
          path={t('pages.how-to-become-a-seller')}
          element={<CMSDetail _id={12} />}
        />
        <Route
          path={t('pages.privacy-policy')}
          element={<CMSDetail _id={9} />}
        />
        <Route
          path={t('pages.shipping-and-payment-policy')}
          element={<CMSDetail _id={7} />}
        />
        <Route
          path={t('pages.terms-and-conditions-purchase')}
          element={<CMSDetail _id={15} />}
        />
        <Route
          path={t('pages.terms-and-conditions-sellers')}
          element={<CMSDetail _id={16} />}
        />

        <Route
          path={t('pages.manuals-and-files')}
          element={<CMSDetail _id={17} />}
        />

        <Route
          path={`/${t('pages.contest')}`}
          element={<CMSDetail _id={18} showRegisterButton centerTitle />}
        />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  )
}

export default RoutesManager
